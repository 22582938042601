.keyleftGrid {
  display: flex;
  justify-content: end;
  align-items: center;
  @media (max-width: 960px) {
    justify-content: start;
  }
}
.fullKeyFeatures {
  @media (max-width: 960px) {
    padding: 0 24px;
  }
}

.keyRightGrid1 {
  display: flex;
  gap: 30px;
  margin-bottom: 10px;
}
.keyRightGrid1T {
  color: #fff;
  font-family: Open Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  @media (max-width: 960px) {
    line-height: 30px;
   }
}
.keyRightGrid1D {
  color: rgba(255, 255, 255, 0.8);
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.keyFeaturesTitle {
  color: #fff;
  font-family: Open Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}
.keyFatures2 {
  color: #fff;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 2px;
}
.keyFatures3 {
  color: rgba(255, 255, 255, 0.8);
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
