.builtContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.builtUpContent {
  width: 100%;
  max-width: 951px;
  text-align: center;
  margin-bottom:95px;
}
.builtHeading {
  color: #fff;
  font-family: "Open Sans";
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 31px;
}
.builtSubHeading {
  color: rgba(255, 255, 255, 0.8);
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.gridRightBox{
    // width:100%;
    max-width: 491px;
     @media (max-width: 960px) {
     padding:0 24px;
    }
}
.GridfullBox{
    margin-bottom: 162px;
}
.keyRightGridPArent{
    width:100%;
    max-width: 584px;
}

.gridsImage{
    display: flex;
    justify-content: center;
}

