.footer {
  background-color: #010116;
  height: 287px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
}
.privacy,
.terms,
.contact {
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; 
  cursor: pointer;
}

.privacy {
    padding-right: 34px;
    border-right: 1px solid #242424;
}
.terms{
    padding-left: 34px;
    padding-right: 34px;
    border-right: 1px solid #242424;
}
.contact{
  padding-left: 34px;
}


@media screen and (max-width: 768px) {
  .privacy, .terms, .contact {
      padding-left: 20px;
      padding-right: 20px;
  }
}

@media screen and (max-width: 576px) {
  .privacy, .terms, .contact {
      padding-left: 10px;
      padding-right: 10px;
  }
}


.footerBottom{
    display: flex;
    padding-bottom: 31px;
}

.fullBottom{
    border-bottom: 1px solid #242424;
    height:1px;
    width:100%;
   
}
.copyRightSection{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  
   
}