.image-container {
  width: 100%;
  /* height: 100vh;  */
  overflow: hidden;
  }
  
  .animated-gif {
    width: 100%;
    height: 100%; 
    object-fit: cover; 
    animation: playGif 1s infinite;
  }
  
  @keyframes playGif {
    to {
      opacity: 8; 
    }
  }