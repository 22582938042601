.headerContainer {
  position: fixed;
  top: 0;
  background: #010127;
  height: 75px;
  width: 100%;
  display: flex;
  z-index: 99;
  justify-content: space-between;
  align-items: center;
  img {
    width: 70px;
    height: 70px;
    margin: auto 0 auto 10%;
    border-radius: 100%;
  }
  button {
    margin: 0 10% 0 0;
    border-radius: 300px;
    width: 150px;
    height: 40px;
    font-weight: 400;
    cursor: pointer;
    border: 1px solid #4040dd;
    color: #fff;
    background-color: #10104f;
    @media (max-width: 600px) {
      margin: 0;
    }
  }
  button:active {
    transform: translateY(2px);
  }
}
.paragraphTag{
  font-family: "Open Sans";
}
