.righttextDataContainer {
  display: flex;
  align-items: center;
}

.leftimageContainer {
  width: 100%;
  height: 100%;
}

.leftimageContainer img {
  width: 100%;
  height: 100%;
}

.rigthtextData h2 {
  color: #fff;
  font-family: Open Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.rigthtextData h5 {
  color: #fff;
  font-family: Open Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.rigthtextData p {
  color: rgba(255, 255, 255, 0.8);
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.textOnLeft h2 {
  color: #fff;
  font-family: Open Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.textOnLeft h5 {
  color: #fff;
  font-family: Open Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.textOnLeft p {
  color: rgba(255, 255, 255, 0.8);
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.textOnLeft .alignCenter {
  display: flex;
  align-items: center;
}

.righttextDataContainer {
  height: 100%;
}

.videobackgroundContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 899px) {
  .textOnLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
  }

  .reverseGridContainer {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .disappergrid {
    display: none;
  }

  .reversegridContainerInnerSecond {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .rigthtextData {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    flex-direction: column;
  }
}
.videobackgroundContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding-bottom: 100px;
  margin-top: 50px;
}


.tilt-container {
  height: 100%;
  cursor: pointer;
  transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);
  width: 50%;
  height: 500px;
  overflow: hidden;
  transform-origin: top center;
  transition: transform 0.3s ease;
  box-shadow: 0 60px 100px rgba(16, 76, 134, 0.12),
    0 58px 75px rgba(16, 76, 134, 0.08), 0 31px 50px rgba(16, 76, 134, 0.12),
    0 17px 30px rgba(16, 76, 134, 0.06), 0 8px 20px rgba(16, 76, 134, 0.05),
    0 8px 70px rgba(16, 76, 134);

  opacity: 1;
  
}
@media screen and (max-width: 800px) {
  .tilt-container{
  width: 90%;
  height: 300px;

  }
}
