.registerContainer {
  background: #220D41;
  padding-top: 90px;
  color: #fff;
  .introWrapper {
    width: 50%;
    margin: 3rem auto auto auto;
    text-align: center;
    @media (max-width: 960px) {
      width: 78%;
    }
    h1 {
      font-size: 60px;
      font-weight: 700;
      margin: 0;
      font-family: "Red Rose";
      @media (max-width: 600px) {
        font-size: 40px;
      }
    }
    h2 {
      font-size: 30px;
      margin: 15px 0 0 0;
      font-family: "Open Sans";
      font-weight: 700;
    }
    p {
      font-size: 18px;
      margin: 13px 0 0 0;
      font-weight: 400;
    }
  }
  .downloadWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      border-radius: 100%;
      margin-top: 1rem;
      @media (max-width: 600px) {
        width: 100%;
      }
    }
    h2 {
      color: #fff;
      font-size: 38px;
      font-weight: 700;
      text-align: center;
    }
    div {
      display: flex;
      align-items: center;
      @media (max-width: 500px) {
        flex-direction: column;
      }
      img {
        height: 88px;
        width: 227px;
      }
    }
  }
  .aboutusWrapper {
    margin-top: 5rem;
    .header {
      text-align: center;
      position: relative;
      font-family: Open Sans;

      h2 {
        font-size: 11em;
        margin: 0;
        -webkit-text-stroke: 1px #40405d;
        color: #010127;
        @media (max-width: 640px) {
          display: none;
        }
      }
      h3 {
        position: absolute;
        font-size: 4.5em;
        bottom: -3rem;
        width: 100%;
        text-align: center;
        @media (max-width: 650px) {
          top: -9rem;
        }
        @media (max-width: 600px) {
          left: 0;
          text-align: center;
          top: -9rem;
        }
          @media (max-width: 400px) {
          left: 0;
          text-align: center;
          font-size: 30px;
          top: -6rem;
        }
      }
    }
  }
}
